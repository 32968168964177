import { ConnectConfiguratie } from './environment.service';

class Conversie2Configuratie extends ConnectConfiguratie {
  configurationName(): string {
    return 'Conversie2';
  }

  production(): boolean {
    return false;
  }

  DEBUG(): boolean {
    return true;
  }

  getPrefix(): string {
    return null;
  }
  isSecure(): boolean {
    return true;
  }
  idpUrl(): string {
    return `https://inloggen.inkijk2.somtoday.nl`;
  }
  idpIssuer(): string {
    return `https://inkijk2.somtoday.nl`;
  }
  apiUrl(): string {
    return `https://api.connect.inkijk2.somtoday.nl/`;
  }

  dashboardApiUrl(): string {
    return `https://etl.connect.inkijk2.somtoday.nl`;
  }

  frontendUrl(): string {
    return `https://connect.inkijk2.somtoday.nl/oauth`;
  }

  analyticsId(): string {
    return 'UA-163203739-2';
  }

  bureauICEKoppelpartijUUID(): string {
    return '10d97656-ed1e-4588-9834-aab9b53924e5';
  }

  wiserClientId(): string {
    return 'db07210a-9550-44af-ba79-64a236ea5a4d-2';
  }

  validationJwks(): object {
    return {keys: [{kty: 'RSA', 'x5t#S256': 'mv7v4ShcQ1kbEz5ANxZDQq-m2Cvpw0NUEKGcktN7NYg', e: 'AQAB', use: 'sig', kid: 'iridiumidp-109764389594938111133130305219478423444', x5c: ['MIIDTTCCAjUCEFKT2jvKUhWDB3CQpxe4B5QwDQYJKoZIhvcNAQELBQAwZTELMAkGA1UEBhMCTkwxGjAYBgNVBAoMEVRvcGljdXMuRWR1Y2F0aW9uMREwDwYDVQQLDAhTb210b2RheTEnMCUGA1UEAwweU29tdG9kYXkgSW5raWprMiBBdXRoZW50aWNhdG9yMB4XDTIyMDExOTE0NTMxOFoXDTI3MTIzMTIyNTk1OVowZTELMAkGA1UEBhMCTkwxGjAYBgNVBAoMEVRvcGljdXMuRWR1Y2F0aW9uMREwDwYDVQQLDAhTb210b2RheTEnMCUGA1UEAwweU29tdG9kYXkgSW5raWprMiBBdXRoZW50aWNhdG9yMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAtYYssjBAHP/hcuudVJ1lX6EXH1L1q6XnZ2HqfSdQT8j9PNR5Y3yE44dVpWYYq8IVRT+fir8/vK71Q3w0JrdYkWS7K2OlUf9E4HLg15anP416KynqZgzjFJzKqaiPzU96bAz3xiyBOiLSLR5JTXHbz18yzuA+j4tI3Aog1IYUwXYWbYYU3qt+bp5CtD6BU6lVs0AO8NOILv9e8sLp6zStlmUI2P/t4HlRWsnNFc8gsutKm++//NfuBF7ycl4UiK959GuxSL2wuXtzdx8Od8J/crPX7of6hIqt3mBNv6RsBpxcQG5YrY+53GZLmlZUZOLpVX9plxCgzOgrDF0+bv47uQIDAQABMA0GCSqGSIb3DQEBCwUAA4IBAQCnKl80FvlMJRM7ZdHqSjErwNGcOdTo2QX5w/hKUvsFRLqXKxG6sHBsSn9Jv1UI1zkKFz7r+B479VBAFUDV3auaNbDvYW/OHaPfjoGbBxlIf5GyMyPAeHxFNnBVtagwou6X/Lsv6bxwr8+PZKNT0akXaP9cqgZQeO5z8y13bmiTNQXsbmV7eyrpwosKiYh+240IfZs9+cmsrLCZcfkUyHaBVZJBDihT97+X/zbXM/y4+qQVM2g4bqFEcleA1N1fklMGm+u6ump5tUAdsv31/mtukta6Rqpv6xv13TWKKjIihNN1eyaykKOzY0wYUUziJIgy5soVxJpiCahf2wRoKuA9'], alg: 'RS256', n: 'tYYssjBAHP_hcuudVJ1lX6EXH1L1q6XnZ2HqfSdQT8j9PNR5Y3yE44dVpWYYq8IVRT-fir8_vK71Q3w0JrdYkWS7K2OlUf9E4HLg15anP416KynqZgzjFJzKqaiPzU96bAz3xiyBOiLSLR5JTXHbz18yzuA-j4tI3Aog1IYUwXYWbYYU3qt-bp5CtD6BU6lVs0AO8NOILv9e8sLp6zStlmUI2P_t4HlRWsnNFc8gsutKm--__NfuBF7ycl4UiK959GuxSL2wuXtzdx8Od8J_crPX7of6hIqt3mBNv6RsBpxcQG5YrY-53GZLmlZUZOLpVX9plxCgzOgrDF0-bv47uQ'}]};
  }
}

const conversieConfig: Conversie2Configuratie = new Conversie2Configuratie();

export const environment = conversieConfig.build();

import {createAction, props} from '@ngrx/store';
import {
  AVGExtendedKoppeling,
  AVGExtendedKoppelpartij,
  AVGExtendedVestiging
} from 'src/app/dto/avg-dashboard-model-classes';
import { AVGSemConsent, AVGSemPartyMarketplaceEntry, AVGSemPartyViewPageEntry } from 'src/generated/avg-client';
import {Payload} from '../payload';
import {
  ExtendedKoppeling,
  Webservice,
  WebserviceAccountGebruik,
  WebserviceDataPoint,
} from './privacydashboard.state';

export const fetchKoppelpartijen = createAction('[Privacydashboard] Fetch koppelpartijen');

export const setKoppelpartijen = createAction('[Privacydashboard] Set koppelpartijen', props<Payload<AVGExtendedKoppelpartij[]>>());

export const setVestigingen = createAction('[Privacydashboard] Set vestigingen', props<Payload<AVGExtendedVestiging[]>>());

export const fetchGeselecteerdeKoppelpartij = createAction('[Privacydashboard] Fetch the selected koppelpartij', props<{ koppelpartijUUID: string; }>());

export const fetchGeselecteerdeKoppelingen = createAction('[Privacydashboard] Fetch the selected koppelingen', props<{ koppelpartijUUID: string; vestigingUUIDs: string[] }>());

export const setGeselecteerdeKoppelingen = createAction('[Privacydashboard] Set the selected koppelingen', props<{ koppelingen: ExtendedKoppeling[]}>());

export const setGeselecteerdeKoppelpartij = createAction('[Privacydashboard] Set the selected koppelpartij', props<{ koppelpartij: AVGExtendedKoppelpartij}>());

export const setWebservices = createAction('[Privacydashboard] Set the webservices', props<Payload<Webservice[]>>());

export const setGeselecteerdeWebservice = createAction('[Privacydashboard] Set the selected webservice', props<{ webservice: Webservice}>());

export const setWebserviceAccountGebruik = createAction('[Privacydashboard] Set accountgebruik of the selected webservice', props<Payload<WebserviceAccountGebruik[]>>());

export const setWebserviceWeekGebruik = createAction('[Privacydashboard] Set weekgebruik of the selected webservice', props<Payload<WebserviceDataPoint[]>>());

export const removeGeselecteerdeWebservice = createAction('[Privacydashboard] Remove the selected webservice');

export const setGoBackURL = createAction('[Privacydashboard] Set correct URL in go back header', props<{ url: string; }>());

export const deleteKoppeling = createAction('[Privacydashboard] Delete koppeling', props<{uuid: string, vestigingen: string[]}>());

export const saveKoppeling = createAction('[Privacydashboard] Save koppeling', props<{koppelingen: AVGExtendedKoppeling[], koppelpartij: { uuid: string, naam: string }, nieuw: boolean}>());

export const saveOpLeerlingGeautoriseerdeKoppeling = createAction('[Privacydashboard] Save opLeerlingGeautoriseerdeKoppeling', props<{koppeling: AVGExtendedKoppeling, koppelpartij: { uuid: string, naam: string }, nieuw: boolean}>());

export const fetchSemParties = createAction('[Privacydashboard] Fetch SEM Parties');

export const semPartiesFetched = createAction('[Privacydashboard] SEM Parties fetched', props<{semParties: AVGSemPartyMarketplaceEntry[]}>());

export const fetchSemPartyDetails = createAction('[Privacydashboard] Fetch SEM Party Details', props<{semPartyUUID: string}>());

export const semPartyDetailsFetched = createAction('[Privacydashboard] SEM Party Details fetched', props<{semPartyDetails: AVGSemPartyViewPageEntry}>());

export const updateSemPartyConsent = createAction('[Privacydashboard] update SEM Party Consent', props<{semPartyUUID: string, digiDeliveryId: string, consent: boolean}>());

export const semPartyConsentUpdated = createAction('[Privacydashboard] SEM Party Consent updated', props<{consent: AVGSemConsent[]}>());

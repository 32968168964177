import {EnabledConfig, RequiredConnectConfig} from './required.connect.config';

export abstract class ConnectConfiguratie {

  constructor() {

  }

  public abstract configurationName(): string;

  public abstract production(): boolean;

  public abstract DEBUG(): boolean;

  public apiUrl(): string {
    return `http${this.isSecure() ? 's' : ''}://api.connect${this.getPrefix() ? '.' + this.getPrefix() : ''}.somtoday.nl/`;
  }

  public dashboardApiUrl(): string {
    return `http${this.isSecure() ? 's' : ''}://etl.connect${this.getPrefix() ? '.' + this.getPrefix() : ''}.somtoday.nl/`;
  }

  public idpUrl(): string {
    return `http${this.isSecure() ? 's' : ''}://inloggen${this.getPrefix() ? '.' + this.getPrefix() : ''}.somtoday.nl`;
  }

  public idpIssuer(): string {
    return `http${this.isSecure() ? 's' : ''}://${this.getPrefix() ? this.getPrefix() + '.' : ''}somtoday.nl`;
  }

  public frontendUrl(): string {
    return `http${this.isSecure() ? 's' : ''}://connect${this.getPrefix() ? '.' + this.getPrefix() : ''}.somtoday.nl/oauth`;
  }

  public wiserClientId(): string {
    return 'EEFC5522-6BDB-4FA9-8EA5-8657FB682E3E';
  }

  public bureauICEKoppelpartijUUID(): string {
    return '5fc86029-799c-4867-b755-c456df724344';
  }

  abstract getPrefix(): string;

  abstract isSecure(): boolean;

  abstract validationJwks(): object;

  public idpClientId(): string {
    return 'pupilportal';
  }

  public analyticsId(): string {
    return '';
  }

  newDashboardsEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  newStudiewijzerLinkjesEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  oldDashboardsEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  oldStudiewijzerLinkjesEnabled(): EnabledConfig {
    return {
      enabled: true
    };
  }

  public build(): RequiredConnectConfig {
    return {
      configurationName: this.configurationName(),
      production: this.production(),
      DEBUG: this.DEBUG(),
      ourRedirectUrl: this.frontendUrl(),
      idpClientId: this.wiserClientId(),
      idpUrl: this.idpUrl(),
      idpIssuer: this.idpIssuer(),
      apiUrl: this.apiUrl(),
      dashboardApiUrl: this.dashboardApiUrl(),
      validationJwks: this.validationJwks(),
      buildMoment: '06/02/2024 14:14:42',
      analyticsId: this.analyticsId(),
      bureauICEKoppelpartijUUID: this.bureauICEKoppelpartijUUID(),
      oldDashboardsEnabled: this.oldDashboardsEnabled(),
      newDashboardsEnabled: this.newDashboardsEnabled(),
      oldStudiewijzerLinkjesEnabled: this.oldStudiewijzerLinkjesEnabled(),
      newStudiewijzerLinkjesEnabled: this.newStudiewijzerLinkjesEnabled(),
    };
  }
}

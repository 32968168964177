import {Component, OnInit, ChangeDetectionStrategy, Input, OnDestroy} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import {map} from 'rxjs/operators';
import {Endpoint, Veld} from 'src/app/state/dev-koppelpartij/dev-koppelpartij.state';
import {InputTextareaViewModel} from '../input-textarea/input-textarea.component';

@Component({
    selector: 'app-input-read-permission',
    templateUrl: './input-read-permission.component.html',
    styleUrls: ['./input-read-permission.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InputReadPermissionComponent implements OnInit, OnDestroy {
    @Input()
    public viewModel: InputReadPermissionViewModel;

    public viewModelTextArea: InputTextareaViewModel;

    public textFieldOpened = false;

    private subscriptions = [];

    constructor() { }

    ngOnInit(): void {
      this.viewModelTextArea = {
        onGetInput: this.viewModel.onGetVeld.pipe(map(v => v?.properties[0]?.description )),
        doOnSave: new Subject<string>(),
        doOnCancel: new Subject<string>(),
      };
      this.subscriptions.push(this.viewModelTextArea.doOnSave.subscribe(property => {
        this.viewModel.onAdditionalPropertyClicked.next(property);
        this.closeTextField();
      }));
      this.subscriptions.push(this.viewModelTextArea.doOnCancel.subscribe(_ => this.closeTextField()));
    }

    public optioneelCheckboxStyle(veld: Veld): string {
        return 'checkbox clickable checkbox-' + (veld.selected ? 'checked' : 'unchecked');
    }

    public verplichtCheckboxStyle(veld: Veld): string {
        return 'checkbox clickable checkbox-' + (veld.verplicht ? 'checked' : 'unchecked');
    }

    onVerplichtClicked(): void {
        this.viewModel.onVeldVerplichtClicked.next();
    }

    onOptioneelClicked(): void {
        this.viewModel.onVeldOptioneelClicked.next();
    }

    public propertyClass(veld: Veld): string {
      if (veld.properties.length === 0) {
        return 'remark add-property';
      } else {
        return 'remark warning';
      }
    }

    public showTextField(): void {
      this.textFieldOpened = true;
    }

    public closeTextField(): void {
      this.textFieldOpened = false;
    }

    ngOnDestroy() {
      this.subscriptions.forEach(s => s.unsubscribe);
    }
}

export interface InputReadPermissionViewModel {
    onGetEndpoint: Observable<Endpoint>;
    onGetVeld: Observable<Veld>;

    onAdditionalPropertyClicked: Subject<string>;
    onVeldOptioneelClicked: Subject<void>;
    onVeldVerplichtClicked: Subject<void>;
}
